import logo from 'src/assets/images/logo_header.svg';
import whitepaper from 'src/assets/images/file-list.svg';
import ru from 'src/assets/images/ru.svg';
import en from 'src/assets/images/en.svg';
import i18n from 'src/i18n';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import style from './Header.module.scss';

function Header() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const pathEn = `${location.pathname}`.includes('/en');

  const updateLanguage = async () => {
    const lang = pathEn ? 'ru' : 'en';

    if (lang === 'ru') {
      navigate(`/`, { replace: true });
    } else {
      navigate('/en/', { replace: true });
    }

    await i18n.changeLanguage(lang);
    await localStorage.setItem('x11_bank_lang', lang);
  };

  useEffect(() => {
    (async () => {
      const lang = await localStorage.getItem('x11_bank_lang');
      if (!lang) {
        await localStorage.setItem('x11_bank_lang', i18n.language);
      }
    })();
  }, [location.pathname, navigate, pathEn]);

  return (
    <div className={style.header}>
      <img src={logo} alt="logo_header" />
      <div className={style.headerButtons}>
        <div
          className={style.language}
          onClick={updateLanguage}
          aria-hidden="true"
        >
          <img src={pathEn ? en : ru} alt="flag" />
          <p>{t('header.lang')}</p>
        </div>
        <a
          className={style.item}
          href={`https://app.bank11.io/${pathEn ? 'en/' : ''}authentication/`}
        >
          <img src={whitepaper} alt="whitepaper" />
          <p>{t('header.entrance')}</p>
        </a>
      </div>
    </div>
  );
}

export default Header;
