import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationsRu from 'src/locales/ru/translation.json';
import translationsEn from 'src/locales/en/translation.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      ru: { translation: translationsRu },
      en: { translation: translationsEn },
    },
    fallbackLng: ['ru'],
    debug: true,
    detection: {
      order: ['path'],
      lookupFromPathIndex: 0,
    },
    interpolation: {
      escapeValue: false,
      formatSeparator: '.',
    },
  });

export default i18n;
